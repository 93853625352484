.slider-container {
    width: 100%;
}

.slider-labels {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.slider-poll {
    background: rgb(63,94,251);
    background: linear-gradient(90deg, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%);

    background: rgb(120,125,150);
    background: linear-gradient(90deg, rgba(142,39,60,1) 0%, rgba(120,125,150,1) 100%);
}

.option-title {
    min-width:20vw;
    padding:20px;
    font-size:4vh;
    background:transparent;
    z-index: 999 !important;
    text-shadow: 0px 0px 10px #111;
}

.title-0 {
    text-align: left;
}

.title-100 {
    text-align: right;
}

.option-title-spacer {

}