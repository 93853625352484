.vote-signal {
  width: 4vw;
  height: 4vw;
  background-color:red;
  border-radius:4vw; 
  position: absolute;
  margin-left:-2vw;
  margin-top:-2vw;
}


@media only screen and (max-width: 600px) {
  .vote-signal {
    width: 20vw;
    height: 20vw;
    border-radius:10vw; 
    margin-left:-10vw;
    margin-top:-10vw;
  }
}