.poll-title {
    font-size:5vh;
    text-align: center;
    padding:30px;
    background-color:#1a1a1a;
    z-index: 5000;
}

.poll-options-container {
    display: flex;
    height: 100%;
    cursor: pointer;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.poll-option {
    width: 50%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size:5vh;
    padding: 30px;
    background:transparent;
    z-index: 999 !important;
    text-shadow: 0px 0px 10px #111;
}

.one-option {
    width: 100%;
    height: 100%;
}

.two-option {
    width: 50%;
    height:100%;
}

.three-option {
    width: 50%;
}

.four-option {
    width: 50%;
    height: 50%;
    align-self: center;
}

@media only screen and (max-width: 600px) {

  .poll-title {
    font-size:2vh;
  }

  .poll-option {
    width: 100%;
  }

  .poll-options-container {
    flex-direction: column;
  }
}