* { box-sizing: border-box; }

html, body {
    margin:0;
    padding:0;
    background-color:#111;
    color:#efefef;
    font-family: 'Roboto', sans-serif;
    font-weight: light;
    height: 100%;
    overflow: hidden;
    cursor: pointer;
}

.poll-panel {
    width:100vw;
    height:100vh;
    padding-bottom:100px;
    overflow: hidden;
    cursor: pointer;
}