.poll-title {
    font-size:5vh;
    text-align: center;
    padding:30px;
    background-color:#111;
}

.poll-options-container {
    display: flex;
    height: 100%;
    cursor: pointer;
}

.poll-option {
    width: 50%;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size:5vh;
    padding: 30px;
}

.left-poll-option {
    height: 100%;
}

.right-poll-option {
    height: 100%;
}

@media only screen and (max-width: 600px) {

  .poll-title {
    font-size:2vh;
  }

  .poll-option {
    width: 100%;
  }

  .left-poll-option {
    height: 30%;
    border-right: none;
  }

  .right-poll-option {
    height: 30%;
  }

  .poll-options-container {
    flex-direction: column;
  }
}